<!-- This file is generated by @sveltejs/kit — do not edit it! -->
<svelte:options runes={true} />
<script>
	import { setContext, onMount, tick } from 'svelte';
	import { browser } from '$app/environment';

	// stores
	let { stores, page, constructors, components = [], form, data_0 = null, data_1 = null, data_2 = null, data_3 = null } = $props();

	if (!browser) {
		setContext('__svelte__', stores);
	}

	if (browser) {
		$effect.pre(() => stores.page.set(page));
	} else {
		stores.page.set(page);
	}
	$effect(() => {
		stores;page;constructors;components;form;data_0;data_1;data_2;data_3;
		stores.page.notify();
	});

	let mounted = $state(false);
	let navigated = $state(false);
	let title = $state(null);

	onMount(() => {
		const unsubscribe = stores.page.subscribe(() => {
			if (mounted) {
				navigated = true;
				tick().then(() => {
					title = document.title || 'untitled page';
				});
			}
		});

		mounted = true;
		return unsubscribe;
	});
</script>

{#if constructors[1]}
	<svelte:component this={constructors[0]} bind:this={components[0]} data={data_0}>
		{#if constructors[2]}
			<svelte:component this={constructors[1]} bind:this={components[1]} data={data_1}>
				{#if constructors[3]}
					<svelte:component this={constructors[2]} bind:this={components[2]} data={data_2}>
						<svelte:component this={constructors[3]} bind:this={components[3]} data={data_3} {form} />
					</svelte:component>
				{:else}
					<svelte:component this={constructors[2]} bind:this={components[2]} data={data_2} {form} />
				{/if}
			</svelte:component>
		{:else}
			<svelte:component this={constructors[1]} bind:this={components[1]} data={data_1} {form} />
		{/if}
	</svelte:component>
{:else}
	<svelte:component this={constructors[0]} bind:this={components[0]} data={data_0} {form} />
{/if}

{#if mounted}
	<div id="svelte-announcer" aria-live="assertive" aria-atomic="true" style="position: absolute; left: 0; top: 0; clip: rect(0 0 0 0); clip-path: inset(50%); overflow: hidden; white-space: nowrap; width: 1px; height: 1px">
		{#if navigated}
			{title}
		{/if}
	</div>
{/if}